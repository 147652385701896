.container {
  display: flex;
  box-sizing: border-box;
  width: 100%;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.alignCenter {
    align-items: center;
  }

  &.alignStart {
    align-items: flex-start;
  }

  &.alignEnd {
    align-items: flex-end;
  }

  &.alignBaseline {
    align-items: baseline;
  }

  &.justifyCenter {
    justify-content: center;
  }

  &.justifyStart {
    justify-content: flex-start;
  }

  &.justifyEnd {
    justify-content: flex-end;
  }

  &.justifyBaseline {
    justify-content: baseline;
  }

  &.justifyBetween {
    justify-content: space-between;
  }

  &.justifyAround {
    justify-content: space-around;
  }
}