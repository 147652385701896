@import 'src/styles/mixins';

.container {
  background-color: var(--header-background-color);

  &__header {
    box-sizing: border-box;
    height: 80px;
    display: flex;
    align-items: center;
    @include media('<desktop') {
      padding: 0 25px
    }
    
    @include layoutElementContentWidth;
  }

  &__logo {    
    @include media('<phone') {
      width: 85px;
      height: 20px;
    }

    @include media('>=phone') {
      width: 120px;
      height: 30px;
    }
  }
}