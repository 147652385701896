@import 'src/styles/mixins';
@import 'src/styles/include-media';

.container {

  &__row {
    width: 100%;
    @include layoutElementPadding;
    @include layoutElementContentWidth;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }

  .textLink {
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    color: var(--black);
    font-weight: 400;
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
