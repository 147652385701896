@import 'src/styles/mixins';

.pattern {
  position: absolute;
  left: 0;
  bottom: 0;
  @include media('<desktop') {
    display: none;
  }
}

.container {
  background-color: var(--white);
  border-radius: 5px;
  width: 100%;
  margin: 50px 0 10px 0;
  position: relative;
  @include media('<desktop') {
    border-top-width: 4px;
    margin: 0px 0 10px 0;
    padding-top: 30px;
  }

  &.default {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 4px;
      @include gradient-default;
      z-index: 888;

      @include media('<desktop') {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }

    hr {
      @include gradient-default;
    }
  }

  &.success {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 4px;
      background-image: linear-gradient(
        to right,
        var(--success-green),
        var(--color-secondary)
      );
      z-index: 999;

      @include media('<desktop') {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }

    button:last-child{
      @include gradient-success;
    }

    hr {
      background-image: linear-gradient(
        to right,
        var(--success-green),
        var(--color-secondary)
      );
    }

    h1 {
      color: var(--success-green);
    }

    .note {
      &:before {
        background-image: linear-gradient(
          to right,
          var(--success-green),
          var(--color-secondary)
        );
      }
    }
  }

  &.error {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 4px;
      @include gradient-error;
      z-index: 999;

      @include media('<desktop') {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }

    button {
      @include gradient-error;
    }

    hr {
      @include gradient-error;
    }

    h1 {
      color: var(--error-dark);
    }

    svg {
      &.icon {
        path {
          fill: var(--error-dark);
        }
      }
    }
  }

  @include media('<desktop') {
    border-radius: 0;
    border-top-color: var(--white);
  }

  @include media('>=desktop') {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 220px auto;
  }
  @include media('>=desktop', '<desktop-xxxl') {
    padding: 80px 0 90px 0;
  }

  @include media('>=desktop-xxxl') {
    padding: 110px 0 100px 0;
  }

  &__paperContent {
    width: 100%;

    @include layoutElementPadding;

    @include media('>desktop') {
      width: 700px;
    }

    hr {
      border: none;
      margin: 0;
      width: 20px;
      height: 4px;
    }

    h1 {
      position: relative;
    }

    h3 {
      font-weight: 200;
      strong {
        font-weight: 700;
      }
    }

    .note {
      font-weight: 600;
      font-size: 14px;
      margin-top: 30px;
      position: relative;
      padding-left: 10px;
      &:before {
        content: '';
        transform: rotate(-90deg);
        margin-left: -8px;
        width: 16px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 6px;
      }
    }
  }

  &__loader {
    margin: 80px auto;
  }
}
