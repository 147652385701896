@import 'src/styles/normalize.scss';
@import 'src/styles/fonts.scss';
@import 'src/styles/mixins.scss';

body {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: var(--gray-primary);
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media('<desktop') {
    background-color: var(--white);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }

  h1 {
    @include typographyElement(24px, 28px, 28px, 32px, 32px, 36px);
    font-family: var(--font-secondary);
    color: var(--black);
  }

  h3 {
    @include typographyElement(16px, 20px, 18px, 22px, 20px, 24px);
    font-family: var(--font-primary);
    color: var(--black);
  }

  h4 {
    font-family: var(--font-primary);
    color: var(--black);
  }

  p {
    font-family: var(--font-primary);
    color: var(--black);
  }
}