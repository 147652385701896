@import 'src/styles/include-media';

.container {
  grid-area: submit;
  font-family: var(--font-primary);

  &__button, &__buttons {
    margin-top: 50px;
  }

  &__buttons {
    display: inline-flex;
    @include media('<tablet') {
      display: block;
    }

    button {
      @include media('<tablet') {
        margin-bottom: 20px;
      }
    }
  }
}

