@mixin button-core {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  font-family: var(--font-primary);
  width: auto;
  margin: 0 auto;
  min-width: 250px;
  background-size: 100%;
  transition: background-size 0.5s linear;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-size: 250%;
    transition: background-size 0.5s linear;
    opacity: 0.7;
  }
}
