:root {
  --black: #000000;
  --white: #ffffff;
  --header-background-color: #000000;
  --gray-primary: #F2F2F2;
  --gray-secondary: #B9B8B6;
  --gray-tertiary: #CCCCCC;
  --error-light: #e47575;
  --error-dark: #D51111;
  --error-dark-light: #FDEAEA;
  --success-green: #31E89C;
  --color-primary: #FF7033;
  --color-secondary: #6C33E3;
  --color-loader-primary: #FF7033;
  --color-loader-secondary: #6C33E3;
  --font-primary: "Montserrat", sans-serif;
  --gradient-gray: linear-gradient(to right, var(--gray-secondary), var(--gray-secondary));
  --gradient-white: linear-gradient(to right, var(--white), var(--white));
  --transparent: transparent;
  //background pattern image colors
  --pattern-default-one: #fbeae3;
  --pattern-default-two: #fedccd;
  --pattern-default-three: #dccef8;
  --pattern-default-four: #fde3d8;
  --pattern-success-one: #cef9e7;
  --pattern-success-two: #e9fef5;
  --pattern-success-three: #ceeaed;
  --pattern-success-four: #e3fef3;
  --pattern-error-one: #fbccd5;
  --pattern-error-two: #fde3e8;
  --pattern-error-three: #f9c2cd;
  --pattern-error-four: #ffd4dd;
}
[data-theme="FAIREPAY"] {
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --color-primary: #FF7033;
  --color-secondary: #6C33E3;
  --color-loader-primary: #FF7033;
  --color-loader-secondary: #6C33E3;
  --gradient-default: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  --gradient-default-vertical: linear-gradient(to bottom, var(--color-primary), var(--color-secondary));
  --gradient-success: linear-gradient(to right, var(--success-green), var(--color-secondary));
  --gradient-error: linear-gradient(to right, var(--error-light), var(--error-dark));
}
[data-theme="BANCA_SELLA"] {
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --color-primary: #FF7033;
  --color-secondary: #6C33E3;
  --color-loader-primary: #FF7033;
  --color-loader-secondary: #6C33E3;
  --gradient-default: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  --gradient-default-vertical: linear-gradient(to bottom, var(--color-primary), var(--color-secondary));
  --gradient-success: linear-gradient(to right, var(--success-green), var(--color-secondary));
  --gradient-error: linear-gradient(to right, var(--error-light), var(--error-dark));
}
[data-theme="BANCA_VALSABBINA"] {
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --color-primary: #FF7033;
  --color-secondary: #6C33E3;
  --color-loader-primary: #FF7033;
  --color-loader-secondary: #6C33E3;
  --gradient-default: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  --gradient-default-vertical: linear-gradient(to bottom, var(--color-primary), var(--color-secondary));
  --gradient-success: linear-gradient(to right, var(--success-green), var(--color-secondary));
  --gradient-error: linear-gradient(to right, var(--error-light), var(--error-dark));
}
[data-theme="SORGENIA"] {
  --black: #12256a;
  --header-background-color: #ffffff;
  --font-primary: "DIN Pro", sans-serif;
  --font-secondary: "DIN Pro", sans-serif;
  --color-primary: #97d700;
  --color-secondary: #97d700;
  --color-loader-primary: #97d700;
  --color-loader-secondary: #12256a;
  --success-green: #97d700;
  --gradient-default: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  --gradient-default-vertical: linear-gradient(to bottom, var(--color-primary), var(--color-secondary));
  --gradient-success: linear-gradient(to right, var(--success-green), var(--color-secondary));
  --gradient-error: linear-gradient(to right, var(--error-light), var(--error-dark));
}