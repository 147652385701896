@import 'src/styles/include-media';
@import 'src/styles/mixins';

.container {
  width: 100%;
  max-width: 950px;

  &__sticky {
    position: sticky;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: 0 0 3px 1px rgba(31, 42, 69, 0.1);
    font-family: var(--font-primary);
    color: var(--black);
    z-index: 10;
    bottom: 0;
    left: 0;
    min-height: 80px;
  
    &.success {
      button {
        //@include gradient-success;
        background-image: linear-gradient(to right, var(--success-green), var(--color-secondary));
      }
    }
  
    &.error {
      button {
        @include gradient-error;
      }
    }
  }

  &__button {
    margin: 18px 10px;
    align-self: center;
  }
}