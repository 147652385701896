@import 'src/styles/mixins';

.container {
  background-color: var(--gray-primary);
  font-size: 12px;
  line-height: 18px;
  margin-top: 20px;

  @include media('<desktop') {
    background-color: var(--white);
  }

  &__footer {
    position: relative;
    font-family: var(--font-primary);
    &:before {
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background: var(--gradient-default);
    }

    @include layoutElementPadding;
    @include layoutElementContentWidth;

    display: grid;
    align-items: center;

    @include media('>=desktop') {
      grid-template-columns: 1fr 2fr 2fr 7fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        'logo address address address'
        '. privacy cookies transparency';
    }

    @include media('<=desktop') {
      grid-template-columns: 1fr 2fr 2fr 2fr;
      grid-template-rows: 1fr 30px;
      grid-template-areas:
        'logo address address address'
        '. privacy cookies transparency';
    }

    @include media('<tablet') {
      grid-template-columns: 1fr;
      grid-template-rows: 40px 1fr 30px 30px 30px;
      grid-template-areas:
        'logo'
        'address'
        'privacy'
        'cookies'
        'transparency';
    }

    img {
      height: 30px;
      margin-top: 15px;
      margin-right: 20px;
    }
  }

  &__logoLink {
    width: 85px;
    height: 20px;
    margin-right: 20px;
  }

  &__logo {
    grid-area: logo;
  }

  &__privacy {
    grid-area: privacy;
  }

  &__cookies {
    grid-area: cookies;
  }

  &__transparency {
    grid-area: transparency;
    font-size: 12px;
    line-height: 18px;
  }

  &__address {
    grid-area: address;
    font-weight: 400;
    text-align: left;
  }

  &__cookies,
  &__privacy,
  &__transparency {
    text-decoration: underline;
    color: var(--black);
    font-weight: 400;
    &:hover {
      text-decoration: none;
    }
    @include media('>desktop') {
      text-align: left;
    }
  }

}
