@import 'src/styles/mixins';

.container {
  background-color: var(--gray-primary);

  @include media('<desktop') {
    background-color: var(--white);
  }

  &__content {
    @include layoutElementContentWidth;
  }
  &__marginBottom {
    margin-bottom: 50px;
  }
}