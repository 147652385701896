@import 'src/components/lib/scss/button.mixins.scss';
@import 'src/styles/include-media';

@function get-var($map, $prop) {
  $val: map-get($map, $prop);
  @if $val == transparent {
    @return $val;
  } @else {
    @return var(--#{$val});
  }
}

@mixin get-props($map) {
  $props: (background-image, border-color, color);
  @each $prop in $props {
    #{$prop}: get-var($map, $prop);
  }
  svg path {
    fill: get-var($map, color);
  }
}

$variants: (
  primary: (
    background-image: 'gradient-default',
    border-color: 'white',
    color: 'white',
    disabled: (
      background-image: 'gradient-gray',
      border-color: 'white',
      color: 'white',
    ),
  ),
  secondary: (
    background-image: 'gradient-white',
    border-color: 'black',
    color: 'black',
    disabled: (
      background-image: 'gradient-gray',
      border-color: 'white',
      color: 'white',
    ),
  ),
  accordion: (
    background-color: transparent,
    border-color: transparent,
    color: 'black',
    disabled: (
      background-color: transparent,
      border-color: transparent,
      color: inherit,
    ),
    inverted: (
      background-color: transparent,
      border-color: transparent,
      color: 'black',
    ),
  ),
);

.container {
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 30px;

  @include media('<desktop') {
    font-size: 16px;
  }

  @include button-core;

  &__icon {
    &.left {
      margin-right: 8px;
    }

    &.right {
      margin-left: 8px;
    }
  }

  @each $variant-name in map-keys($variants) {
    $variant: map-get($variants, $variant-name);

    &.#{$variant-name} {
      @include get-props($variant);

      $disabled: map-get($variant, disabled);
      $inverted: map-get($variant, 'inverted');

      align-items: center;

      &:disabled {
        @include get-props($disabled);
      }

      @if $variant-name == 'accordion' {
        text-transform: none;
        text-decoration: underline;
        font-weight: normal;
        font-size: 14px;
        padding: 0;
        background-color: transparent;
        border-color: transparent;
        justify-content: flex-end;

        @include media('<tablet') {
          font-size: 14px;
        }
      }
    }
  }
}