@import 'src/styles/mixins';
@import 'src/styles/include-media';

.container {
  width: 100%;
  position: fixed;
  z-index: 11;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  color: var(--black);
  font-family: var(--font-primary);
  padding: 20px;
  box-shadow: 0 0 3px 1px rgba(31, 42, 69, 0.1);

  @include animationFadeInDown;

  @include media('<tablet') {
    flex-direction: column;
  }

  &__content {
    padding: 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-primary);
    @include media('<tablet') {
      margin-bottom: 20px;
    }

    a {
      font-size: 14px;
    }
  }

  .textLink {
    color: var(--black);
  }
}
