.container {
  text-decoration: underline;
  color: var(--black);
  font-family: var(--font-primary);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: inline-flex;

  svg {
    height: 12px;
  }

  svg path {
    fill: var(--black);
  }

  &:hover {
    text-decoration: none;
    color: var(--black);
    fill: var(--black);
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--gray-secondary);
    fill: var(--gray-secondary);

    svg path {
      fill: var(--gray-secondary);
    }
  }

  &__children {
    margin: 0 8px;

    &.noIcon {
      margin: 0;
    }
  }
}