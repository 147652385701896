@import 'src/styles/breakpoints.scss';

@mixin layoutElementPadding {
  @include media('<=phone') {
    padding: 15px 25px;
  }
  @include media('>phone', '<=tablet') {
    padding: 20px;
  }
  @include media('>tablet', '<=desktop') {
    padding: 25px 40px;
  }
  @include media('>desktop') {
    padding: 25px 0 80px 0;
  }
}

@mixin layoutElementContentWidth {
  @include media('<desktop') {
    width: 100%;
  }
  @include media('>=desktop', '<desktop-xxxl') {
    width: 970px;
  }
  @include media('>=desktop-xxxl') {
    width: 1280px;
  }
}

@mixin typographyElement(
  $smallFontSize,
  $smallLineHeight,
  $mediumFontSize,
  $mediumLineHeight,
  $largeFontSize,
  $largeLineHeight
) {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;

  @include media('<desktop') {
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
  }
  @include media('>=desktop', '<desktop-xxxl') {
    font-size: $mediumFontSize;
    line-height: $mediumLineHeight;
  }
  @include media('>=desktop-xxxl') {
    font-size: $largeFontSize;
    line-height: $largeLineHeight;
  }
}

@mixin animationFadeInDown {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: fadeInDown;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin gradient-default {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
}

@mixin gradient-error {
  background: linear-gradient(to right, var(--error-light), var(--error-dark));
}

@mixin gradient-success {
  background: linear-gradient(to right, var(--success-green), var(--color-secondary));
}

@mixin decor($gradient: 'default') {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 4px;
    z-index: 999;

    @if $gradient == 'success' {
      @include gradient-success;
    } @else if $gradient == 'error' {
      @include gradient-error
    } @else {
      @include gradient-default;
    }

    @include media('<desktop') {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  hr {
    @if $gradient == 'success' {
      @include gradient-success;
    } @else if $gradient == 'error' {
      @include gradient-error
    } @else {
      @include gradient-default;
    }
  }
}

@mixin chevronRotated {
  &.expanded {
    svg {
      transition: all 250ms ease;
      transform: rotate(180deg);
    }
  }
}