.logo {
  &.black {
    fill: var(--black);
  }
  &.white {
    fill: var(--white);
  }
  &.gradient {
    fill: inherit
  }
  &.logoSorgenia {
    max-height: 50px;
  }
}

